<template>
  <div class="nav-tab">
    <li
      class='nav-item'>
      <div class='nav-link'
           :class="{ 'chosen': containerID === id }"
           @click="setInputMode"
           v-show="!inputMode"
      >
        {{name ? name : ""}}
      </div>
      <div class='nav-link tab-input'
           :class="{ 'chosen': containerID === id }"
           v-show="inputMode"
      >
        <v-text-field
          style="padding-top: 0px;"
          label="Create Location"
          v-model="newName"
          class="pa-3"
          :hide-details="true"
        ></v-text-field>
        <div class="input-right" @click.stop="addNewContainer" v-show="inputMode">
          <Icon
            iconName="check-circle"
            size="8px"
            color="green"
          />
        </div>
      </div>
      <div class="top-right no-hov-show" @click.stop="setInputMode" v-show="inputMode" >
        <Icon
          iconName="times"
          size="4px"
          color="grey"
        />
      </div>
    </li>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Icon from '@/components/elements/Icon'
import TreeModel from 'tree-model-improved'
import { cloneDeep } from 'lodash'
const treeModel = new TreeModel({
  modelComparatorFn: (a, b) => a.name?.toLowerCase() > b.name?.toLowerCase()
})

const uuidv4 = require('uuid').v4

export default {
  name: 'NewTab',
  components: { Icon },
  props: {
    name: String,
    id: String,
    setActiveParent: Function,
    containerID: String,
    currNavs: Array,
    toggleEditMode: Function
  },
  data () {
    return {
      containerNode: {},
      currLevelNode: {},
      inputMode: false,
      tree: [],
      newName: ''
    }
  },
  computed: {
    ...mapGetters({
      getContainers: 'containers/getContainers',
      getMyIds: 'login/getMyIds'
    })

  },
  mounted () {
    if (typeof this?.tree === 'object') {
      this.runSetup()
    }
  },
  methods: {
    ...mapActions({
      updateContainer: 'containers/updateContainer'
    }),
    runSetup () {
      this.tree = treeModel.parse(cloneDeep(this.getContainers))
      this.containerNode = this.tree.first(node => {
        return node.model.id === this.getContainers.id // grab top level container id
      })
      this.currLevelNode = this.tree.first(node => {
        return node.model.id === this.currNavs[0].id // grab sibling level of new Container
      })
    },
    editContainer () {
      console.log('here we go')
    },
    setInputMode () {
      this.inputMode = !this.inputMode
    },
    updateStateContainer (newParent, newTab) {
      this.updateContainer({ container: newParent, msgType: 'container_create', updatedObj: newTab })
      this.editMode = false
    },
    addNewContainer () {
      let newTab = {
        id: uuidv4(),
        parentId: '',
        customerId: this.getMyIds?.CUSTOMER?.[0] ?? 'DEFAULT',
        fulfillerId: this.getMyIds?.FULFILLER?.[0] ?? 'DEFAULT',
        name: this.newName,
        devices: [],
        children: [],
        metadata: {
          name: '',
          status: '',
          text: ''
        }
      }
      this.currLevelNode.parent.addChild(treeModel.parse(newTab))
      this.updateStateContainer(this.tree.model, newTab) // send container to update in state & local
      setTimeout(() => {
        this.inputMode = !this.inputMode
        this.toggleEditMode()
      }, 600)
    }
  }
}
</script>
